import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step crl inspect`}</strong>{` -- print certificate revocation list (CRL) details in human-readable format`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step crl inspect <file|url>
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step crl inspect`}</strong>{` validates and prints the details of a certificate revocation list (CRL).
A CRL is considered valid if its signature is valid, the CA is not expired, and the next update time is in the future.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`file|url`}</inlineCode>{`
The file or URL where the CRL is. If `}<inlineCode parentName="p">{`--from`}</inlineCode>{` is passed it will inspect
the certificate and extract the CRL distribution point from.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--format`}</strong>{`=`}<inlineCode parentName="p">{`format`}</inlineCode>{`
The output format for printing the introspection details.`}</p>
    <p><inlineCode parentName="p">{`format`}</inlineCode>{` is a string and must be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`text`}</strong>{`: Print output in unstructured text suitable for a human to read.
This is the default format.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`json`}</strong>{`: Print output in JSON format.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`pem`}</strong>{`: Print output in PEM format.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--ca`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The certificate `}<inlineCode parentName="p">{`file`}</inlineCode>{` used to validate the CRL.`}</p>
    <p><strong parentName="p">{`--from`}</strong>{`
Extract CRL and CA from the URL passed as argument.`}</p>
    <p><strong parentName="p">{`--roots`}</strong>{`=`}<inlineCode parentName="p">{`roots`}</inlineCode>{`
Root certificate(s) that will be used to verify the
authenticity of the remote server.`}</p>
    <p><inlineCode parentName="p">{`roots`}</inlineCode>{` is a case-sensitive string and may be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`file`}</strong>{`: Relative or full path to a file. All certificates in the file will be used for path validation.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`list of files`}</strong>{`: Comma-separated list of relative or full file paths. Every PEM encoded certificate from each file will be used for path validation.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`directory`}</strong>{`: Relative or full path to a directory. Every PEM encoded certificate from each file in the directory will be used for path validation.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--insecure`}</strong></p>
    <h2>{`Examples`}</h2>
    <p>{`Inspect a CRL:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crl inspect --insecure http://ca.example.com/crls/exampleca.crl
`}</code></pre>
    <p>{`Inspect and validate a CRL in a file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crl inspect --ca ca.crt exampleca.crl
`}</code></pre>
    <p>{`Format the CRL in JSON:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crl inspect --insecure --format json exampleca.crl
`}</code></pre>
    <p>{`Inspect the CRL from the CRL distribution point of a given url:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step crl inspect --from https://www.google.com
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      